import styled from "styled-components";

export const Claim = styled.h3`
  font-size: var(--textXL);
  line-height: var(--lineHeightXL);
  color: var(--primary800);
  font-weight: lighter;
  margin-bottom: 0;
  padding: var(--spacingM);
  max-height: 272px;
   @media screen and (min-width: 1128px) {

    padding: 0;
  }
`;

export const Description = styled.div`
  display: none;
  padding: var(--spacingM);
  max-width: 216px;

  @media screen and (min-width: 1128px) {
    display: block;
    padding: 0;
  }
`;
export const Wrapper = styled.div`
  display: grid;
  max-height: 608px;
  border-radius: 24px;
  gap: 16px;
  @media screen and (min-width: 1128px) {
    display: grid;
    border-radius: var(--spacingL);
  grid-template-columns: ${({ onlyMedia, leftImage }) =>
    leftImage || !onlyMedia ? "1fr 2fr" : "auto"};
    grid-template-rows: auto;
    grid-gap: 24px;
    max-height: 416px;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: var(--spacingM);
  background-color: var(--gray100);
  max-height: 272px;
  padding: 45px;
  gap:15px;
  > img {
      max-height: 100%;
      width: auto;
    }
  @media screen and (min-width: 1128px) {
    background-color: var(--gray100);
    max-height: 416px;
    max-width: 384px;
    padding: 60px;
    
  }
  }
`;

export const ContentWrapper = styled.div`
  > img {
    border-radius: var(--spacingM);
    width: 100%;
    height: 320px;
    object-fit: cover;
    margin: 0px;
  }
  @media screen and (min-width: 1128px) {
    > img {
      height: 416px;
    }
  }
`;

export const Iframe = styled.div`
  @media screen and (min-width: 1128px) {
    iframe,
    object,
    embed {
      height: 416px;
      width: 100%;
      border-radius: var(--spacingM);
    }
  }
  @media screen and (max-width: 1128px) {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: var(--spacingM);
    }
  }
`;

export const MediaTestimony = styled.div`
  padding: 24px;
  font-size: var(--textS);
  line-height: var(--lineHeightS);

  @media screen and (min-width: 1128px) {
    display: none;
  }
`;
