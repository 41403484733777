import { renderLineBreaks } from "../../../utils";
import { NextImage } from "..";
import {
  Description,
  Claim,
  Wrapper,
  InfoWrapper,
  ContentWrapper,
  Iframe,
  MediaTestimony,
} from "./styles";

export const BigCardMediaItem = ({
  id,
  claim,
  description,
  iframe,
  image,
  leftImage,
  mediaTestimony,
}) => {
  const onlyMedia = !claim && !description;
  return (
    <Wrapper onlyMedia={onlyMedia} leftImage={leftImage}>
      {!onlyMedia && !leftImage && (
        <InfoWrapper>
          {claim && <Claim>{renderLineBreaks(claim)}</Claim>}
          {description && (
            <Description>{renderLineBreaks(description)}</Description>
          )}
        </InfoWrapper>
      )}
      {leftImage && (
        <InfoWrapper>
          <NextImage media={leftImage} />
        </InfoWrapper>
      )}
      <ContentWrapper>
        {iframe ? (
          <Iframe dangerouslySetInnerHTML={{ __html: iframe }} />
        ) : image ? (
          <NextImage media={image} />
        ) : null}
      </ContentWrapper>
      {mediaTestimony && (
        <MediaTestimony>{renderLineBreaks(mediaTestimony)}</MediaTestimony>
      )}
    </Wrapper>
  );
};

export default BigCardMediaItem;
